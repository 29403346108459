import React from "react";
import Card from "react-bootstrap/Card";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

function BlogCard() {
    return (
        <Card className="blog-card-view">
            <Card.Body>
                <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "justify" }}>
                        Asynchronous programming is a crucial aspect of modern software development, enabling
                        applications to remain responsive while performing time-consuming operations. In C#,
                        asynchronous programming is accomplished using the <code>async</code> and <code>await</code> keywords,
                        along with interfaces like <code>IProgress</code>.
                        <br /><br />
                        The <code>async</code> keyword is used to define asynchronous methods, which can be paused
                        and resumed during execution. These methods typically return a <code>Task</code> or <code>Task&lt;T&gt;</code>
                        representing the asynchronous operation.
                        <br /><br />
                        The <code>await</code> keyword is used to asynchronously wait for the completion of an asynchronous
                        operation. It can only be used within an <code>async</code> method and is used to pause the execution
                        of the method until the awaited operation completes.
                        <br /><br />
                        The <code>IProgress</code> interface is used to report progress information from asynchronous
                        operations back to the caller. It defines a single method, <code>Report</code>, which is called
                        to report progress updates.
                        <br /><br />
                        Here's a simple example demonstrating the usage of <code>async</code>, <code>await</code>, and <code>IProgress</code>:
                    </p>
                    <SyntaxHighlighter language="csharp" style={atomDark}>
                        {`async Task DownloadFileAsync(string url, IProgress<int> progress)
{
  using (var client = new HttpClient())
  {
    using (var response = await client.GetAsync(url, HttpCompletionOption.ResponseHeadersRead))
    {
      var totalBytes = (int)response.Content.Headers.ContentLength;
      var downloadedBytes = 0;
      
      using (var stream = await response.Content.ReadAsStreamAsync())
      {
        var buffer = new byte[8192];
        var bytesRead = 0;

        while ((bytesRead = await stream.ReadAsync(buffer, 0, buffer.Length)) > 0)
        {
          downloadedBytes += bytesRead;
          var percentage = (int)(((double)downloadedBytes / totalBytes) * 100);
          progress.Report(percentage);
        }
      }
    }
  }
}`}
                    </SyntaxHighlighter>
                    <p style={{ textAlign: "justify" }}>
                        In this example, the <code>DownloadFileAsync</code> method asynchronously downloads a file from
                        the specified URL while reporting progress updates using the <code>IProgress</code> interface.
                        <br /><br />
                        Asynchronous programming in C# can greatly improve the responsiveness and scalability of your applications.
                        By leveraging features like <code>async</code>, <code>await</code>, and <code>IProgress</code>, you can
                        efficiently handle long-running operations without blocking the main thread.
                        <br /><br />
                        That concludes our exploration of asynchronous programming in C#. I hope you found this article informative
                        and helpful in your development endeavors.
                    </p>
                </blockquote>
            </Card.Body>
        </Card>
    );
}

export default BlogCard;
