import React, { useState } from "react";
import axios from "axios";
import { AiOutlineMail, AiOutlineUser, AiOutlinePhone } from "react-icons/ai";
import { RiFontColor, RiMessage2Line } from "react-icons/ri";
import Button from "react-bootstrap/Button";

function ContactMe() {
    const [formData, setFormData] = useState({
        messageTitle: "",
        message: "",
        email: "",
        guestUser: "",
        phone: ""
    });

    const [messageSent, setMessageSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [submitting, setSubmitting] = useState(false); // State to track form submission

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Client-side validation
            if (!formData.messageTitle) {
                setMessageSent(false);
                setErrorMessage('Message Title is required.');
                return;
            }
            if (!formData.message) {
                setMessageSent(false);
                setErrorMessage('Message is required.');
                return;
            }
            if (!formData.email) {
                setMessageSent(false);
                setErrorMessage('Email is required.');
                return;
            }
            if (!formData.guestUser) {
                setMessageSent(false);
                setErrorMessage('Guest Name is required.');
                return;
            }
            setSubmitting(true);
            // API call to submit form data using fetch
            const contactApi = "https://p7ay6y6hb8.execute-api.us-east-1.amazonaws.com/v1/messages";
            const response = await fetch(contactApi, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                setErrorMessage('Failed to send message. Please try again later.');
                return;
            }
            setMessageSent(true);
            setErrorMessage("");
            setFormData({
                messageTitle: "",
                message: "",
                email: "",
                guestUser: "",
                phone: ""
            });
        } catch (error) {
            setErrorMessage('Failed to send message. Please try again later.');
            setMessageSent(false);
        } finally {
            // Reset form submitting state
            setSubmitting(false);
        }
    };

    return (
        <div className="contact-me">
            <h2>Contact Me</h2>
            {messageSent && <div style={{ color: 'green' }}>Message sent successfully!</div>}
            <div style={{ color: 'red' }}>{errorMessage}</div>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <AiOutlineMail className="icon" />
                    <input
                        type="text"
                        name="messageTitle"
                        value={formData.messageTitle}
                        placeholder="Message Title"
                        onChange={handleChange}
                    />
                </div>
                <div className="input-group">
                    <RiMessage2Line className="icon" />
                    <textarea
                        name="message"
                        value={formData.message}
                        placeholder="Message"
                        onChange={handleChange}
                    />
                </div>
                <div className="input-group">
                    <AiOutlineMail className="icon" />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="Email"
                        onChange={handleChange}
                    />
                </div>
                <div className="input-group">
                    <AiOutlineUser className="icon" />
                    <input
                        type="text"
                        name="guestUser"
                        value={formData.guestUser}
                        placeholder="Guest Name"
                        onChange={handleChange}
                    />
                </div>
                <div className="input-group">
                    <AiOutlinePhone className="icon" />
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        placeholder="Phone (optional)"
                        onChange={handleChange}
                    />
                </div>
                <Button
                    type="submit"
                    disabled={submitting}
                    style={{
                        cursor: submitting ? 'not-allowed' : 'pointer',
                        opacity: submitting ? 0.7 : 1,
                        pointerEvents: submitting ? 'none' : 'auto',
                        flex: 1,
                        marginTop: 'auto',
                        position: 'relative'
                    }}
                > {submitting ? "Submitting..." : "Send Message"} </Button>

            </form>
        </div>
    );
}

export default ContactMe;
