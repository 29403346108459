import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello, I'm <span className="purple">Abdullah Noman</span>, currently residing in Fairfield, Iowa.
            I'm a skilled software developer with around five years of experience in the software industry.
            I specialize in developing high-quality web applications, utilizing a variety of technologies and tools
            to deliver solutions that meet the needs of clients and users alike.
            <br /><br />
            Throughout my career, I have been involved in all phases of the Software Development Life Cycle,
            from requirements gathering to implementation and maintenance. My expertise lies in leading back-end projects
            using object-oriented programming principles, design patterns, and integrating third-party APIs.
            Notably, I developed complex modules for a financial budgeting solution for TAFE NSW and designed
            and developed several web API applications from scratch.
            <br /><br />
            Currently, I am pursuing distance education courses to complete a master’s degree in computer science,
            and I am available for full-time, W-2 employment opportunities.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Object-Oriented Programming
            </li>
            <li className="about-activity">
              <ImPointRight /> .NET Technologies
            </li>
            <li className="about-activity">
              <ImPointRight /> Clean Architecture
            </li>
            <li className="about-activity">
              <ImPointRight /> Design Patterns
            </li>
            <li className="about-activity">
              <ImPointRight /> Distributed Systems
            </li>
            <li className="about-activity">
              <ImPointRight /> Algorithms
            </li>
            <li className="about-activity">
              <ImPointRight /> Data Structures & Collections
            </li>
            <li className="about-activity">
              <ImPointRight /> System Design
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
