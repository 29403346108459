import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import BlogCard from "./BlogCard";

function Blog() {
  return (
    <Container fluid className="blog-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "100px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.02em", paddingBottom: "20px" }}>
              <strong className="purple"> Understanding IProgress, async, and await in C# </strong>
            </h1>
            <BlogCard />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Blog;