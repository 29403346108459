import React, { useState, useEffect, useRef } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import BlogPost from "./components/Blog/Blog";
import ContactMe from "./components/Contacts/Contact";
import {
  BrowserRouter as Router
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Element, scroller } from "react-scroll";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Element name="home" className="section">
          <Home />
        </Element>
        <Element name="about" className="section">
          <About />
        </Element>
        <Element name="projects" className="section">
          <Projects />
        </Element>
        <Element name="resume" className="section">
          <Resume />
        </Element>
        <Element name="blog" className="section">
          <BlogPost />
        </Element>
        <Element name="contact" className="section">
          <ContactMe />
        </Element>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
