import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import mealigram from "../../Assets/Projects/mealigram.png";
import libraryManagementSystem from "../../Assets/Projects/libraryManagementSystem.png";
import bigDataProject from "../../Assets/Projects/bigDataProject.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently at MIU.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mealigram}
              isBlog={false}
              title="Mealigram"
              description="Modern Web Application project that imitates Instagram, allowing users to create and post recipes. Features include user interaction functionalities such as viewing, saving, commenting, liking, replying to posts, and deleting own recipes."
              techUsed="Express, NodeJS, MongoDB, Angular CLI"
              ghLink="https://github.com/NomanAbdullahAl/final-project-stampcrab"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={libraryManagementSystem}
              isBlog={false}
              title="Library Management System"
              description="Desktop project managing library books and members. Includes role-specific modules, overdue book tracking, and report generation."
              techUsed="Java, Swing"
              ghLink="https://github.com/jansjay/MIU/tree/main/CS401/Project"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bigDataProject}
              isBlog={false}
              title="Big Data Project"
              description="Implemented different MapReduce algorithms like Pair, Stripe, and Inverter Approach to calculate count, average, and relative frequency. Utilized Java, Hadoop, Spark, and Scala."
              techUsed="Java, Hadoop, Spark, Scala"
              ghLink="https://github.com/NomanAbdullahAl/BigData/tree/main/MapReduceProject"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
